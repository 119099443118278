exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-63-fitness-js": () => import("./../../../src/pages/63fitness.js" /* webpackChunkName: "component---src-pages-63-fitness-js" */),
  "component---src-pages-apnahood-js": () => import("./../../../src/pages/apnahood.js" /* webpackChunkName: "component---src-pages-apnahood-js" */),
  "component---src-pages-artha-js": () => import("./../../../src/pages/artha.js" /* webpackChunkName: "component---src-pages-artha-js" */),
  "component---src-pages-assignments-developer-js": () => import("./../../../src/pages/assignments/developer.js" /* webpackChunkName: "component---src-pages-assignments-developer-js" */),
  "component---src-pages-assignments-test-automation-js": () => import("./../../../src/pages/assignments/test-automation.js" /* webpackChunkName: "component---src-pages-assignments-test-automation-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-bakers-market-js": () => import("./../../../src/pages/bakers-market.js" /* webpackChunkName: "component---src-pages-bakers-market-js" */),
  "component---src-pages-bako-js": () => import("./../../../src/pages/bako.js" /* webpackChunkName: "component---src-pages-bako-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-connect-direct-services-js": () => import("./../../../src/pages/connect-direct-services.js" /* webpackChunkName: "component---src-pages-connect-direct-services-js" */),
  "component---src-pages-deal-street-asia-js": () => import("./../../../src/pages/deal-street-asia.js" /* webpackChunkName: "component---src-pages-deal-street-asia-js" */),
  "component---src-pages-faster-fit-case-study-js": () => import("./../../../src/pages/faster-fit-case-study.js" /* webpackChunkName: "component---src-pages-faster-fit-case-study-js" */),
  "component---src-pages-footprints-js": () => import("./../../../src/pages/footprints.js" /* webpackChunkName: "component---src-pages-footprints-js" */),
  "component---src-pages-freshworks-apps-freshcamp-privacy-policy-js": () => import("./../../../src/pages/freshworks-apps/freshcamp/privacy-policy.js" /* webpackChunkName: "component---src-pages-freshworks-apps-freshcamp-privacy-policy-js" */),
  "component---src-pages-freshworks-apps-freshcamp-terms-of-service-js": () => import("./../../../src/pages/freshworks-apps/freshcamp/terms-of-service.js" /* webpackChunkName: "component---src-pages-freshworks-apps-freshcamp-terms-of-service-js" */),
  "component---src-pages-gitex-global-js": () => import("./../../../src/pages/gitex-global.js" /* webpackChunkName: "component---src-pages-gitex-global-js" */),
  "component---src-pages-home-page-marquee-js": () => import("./../../../src/pages/HomePageMarquee.js" /* webpackChunkName: "component---src-pages-home-page-marquee-js" */),
  "component---src-pages-home-page-testimonial-slider-js": () => import("./../../../src/pages/HomePageTestimonialSlider.js" /* webpackChunkName: "component---src-pages-home-page-testimonial-slider-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurecomp-js": () => import("./../../../src/pages/insurecomp.js" /* webpackChunkName: "component---src-pages-insurecomp-js" */),
  "component---src-pages-isima-js": () => import("./../../../src/pages/isima.js" /* webpackChunkName: "component---src-pages-isima-js" */),
  "component---src-pages-moving-images-js": () => import("./../../../src/pages/moving-images.js" /* webpackChunkName: "component---src-pages-moving-images-js" */),
  "component---src-pages-network-insights-js": () => import("./../../../src/pages/network-insights.js" /* webpackChunkName: "component---src-pages-network-insights-js" */),
  "component---src-pages-niradhi-js": () => import("./../../../src/pages/niradhi.js" /* webpackChunkName: "component---src-pages-niradhi-js" */),
  "component---src-pages-offerings-js": () => import("./../../../src/pages/offerings.js" /* webpackChunkName: "component---src-pages-offerings-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-redseer-js": () => import("./../../../src/pages/redseer.js" /* webpackChunkName: "component---src-pages-redseer-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-the-restack-js": () => import("./../../../src/pages/the-restack.js" /* webpackChunkName: "component---src-pages-the-restack-js" */),
  "component---src-pages-transition-js": () => import("./../../../src/pages/transition.js" /* webpackChunkName: "component---src-pages-transition-js" */),
  "component---src-pages-twid-js": () => import("./../../../src/pages/twid.js" /* webpackChunkName: "component---src-pages-twid-js" */),
  "component---src-pages-video-player-js": () => import("./../../../src/pages/videoPlayer.js" /* webpackChunkName: "component---src-pages-video-player-js" */),
  "component---src-pages-warburtons-js": () => import("./../../../src/pages/warburtons.js" /* webpackChunkName: "component---src-pages-warburtons-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

